import { gql } from '@apollo/client';

export const GET_PROTOCOL_ITEMS_WITH_SKILLS = gql`
  query GetProtocolItemsWithSkills($protocolId: ID!, $showSkills: Boolean!) {
    protocolItems(protocolId: $protocolId) {
      id
      description
      code
      domain
      subdomain
      libraryObjective {
        id
        description
        skill {
          id
          name
        }
        currentEvolutionCheckConfigurationId
      }
    }
    skills @include(if: $showSkills) {
      id
      name
    }
  }
`;
