import { gql } from '@apollo/client';

export const GET_SESSION_PLANNING_DETAILS = gql`
  query getSessionPlanningDetails($sessionId: ID!, $objectiveStatuses: [String]) {
    session(id: $sessionId) {
      id
      startScheduledAt
      clinicalCase {
        id
        name
        peis(objectiveStatuses: $objectiveStatuses) {
          id
          objectives {
            id
            description
            strategies {
              id
              name
              definition
              useFor
              itsGoodFor
              abbreviation
            }
            protocolItem {
              id
              description
            }
            programs {
              ... on AbaProgram {
                discipline
                instruction
                activities {
                  name
                }
              }
              ... on OccupationalTherapyProgram {
                discipline
              }
              ... on SpeechTherapyProgram {
                discipline
              }
              ... on SymbolicPlayProgram {
                programType
                targets {
                  id
                  name
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;
