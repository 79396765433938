import { graphql } from 'msw';
import { v4 as uuidV4 } from 'uuid';

export default graphql.query('getClinicalCasePreferences', (_req, res, ctx) => {
  return res(
    ctx.delay(1500),
    ctx.data({
      clinicalCase: {
        __typename: 'ClinicalCase',
        id: uuidV4(),
        preferences: {
          __typename: 'ClinicalCasePreferences',
          id: uuidV4(),
          maxWorkloadPerDay: 4,
          maxWorkloadPerDisciplineDay: [
            {
              discipline: 'ABA',
              maxWorkloadPerDay: 3,
            },
            {
              discipline: 'SPEECH_THERAPY',
              maxWorkloadPerDay: 1.5,
            },
            {
              discipline: 'OCCUPATIONAL_THERAPY',
              maxWorkloadPerDay: 1,
            },
          ],
          canShareRoom: true,
          canShareSchedule: true,
          canShareScheduleContext: 'Test',
          updatedBy: {
            __typename: 'User',
            id: uuidV4(),
            name: 'John Doe',
          },
          updatedAt: '2023-01-01T00:00:00',
        },
      },
    }),
  );
});
